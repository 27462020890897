/* eslint-disable */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiService from "services/apiService";
import months from "util/months";
import * as XLSX from "xlsx";
import DataDisplay from "./DataDisplay";
import { sortByKey } from "util/sortOptions";
import { getUnixTime } from "util/epochTime";
import { BreadCrumb } from "components/BreadCrumb";

import { useTranslation } from "react-i18next";

// import { Modal } from "flowbite-react";
import Modal from "components/Modal";
import GenericModal from "components/Modal";

const schema = yup.object().shape({
  email: yup.string().notRequired().nullable(),
  project: yup.string().notRequired().nullable(),
  year: yup.number().required("Year is Required"),
  month: yup.string().required("Month is Required"),
});
interface OptionProps {
  value: string;
  label: string;
}
const YearOption = [
  {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  {
    label: new Date().getFullYear() - 1,
    value: new Date().getFullYear() - 1,
  },
];
export default function ExportTimeSheet() {
  const methods = useForm({ resolver: yupResolver(schema) });

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState<OptionProps[]>([
    { value: "", label: "All" },
  ]);

  const [project, setProject] = useState<OptionProps[]>([
    { value: "", label: "All" },
  ]);
  const [timesheet, setTimesheet] = useState();
  const [enable, setEnable] = useState(true);
  const [payload1, setPayload1] = useState<any>(null);
  const [message, setMessage] = useState(false); //if it is  true shows empty data pop-up
  // const [inActivedata, setInActiveData] = useState<OptionProps[]>([
  //   { value: "", label: "All" },
  // ]);
  const [userActive, setUserActive] = useState(`users`);
  useEffect(() => {
    setLoading(true);
    apiService
      .get(`api/${userActive}`)
      .then((res) => {
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        const sortedArray = sortByKey(emailOptions, "label");
        const tempArray = [{ value: "", label: "All" }, ...sortedArray];
        setData(tempArray);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, userActive]);

  useEffect(() => {
    apiService
      .get("api/project")
      .then((res) => {
        // console.log('res.data', res.data)
        const getProjectOptions = (projectArray: any[]) => {
          return projectArray.map((project: any) => ({
            value: project.projectId,
            label: project.name,
          }));
        };
        const ProjectOptions = getProjectOptions(res.data);
        const tempArray = [{ value: "", label: "All" }, ...ProjectOptions];
        const sortedArray = sortByKey(tempArray, "label");
        setProject(sortedArray);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const getUserNameByEmail = (email: string): string => {
    const user = data.find((user) => user.value === email);
    return user ? user.label : "";
  };

  // Function to get project name based on projectId
  const getProjectNameById = (projectId: string): string => {
    const projectObj = project.find((proj) => proj.value === projectId);
    return projectObj ? projectObj.label : "";
  };

  function exportTimeSheetToExcel(
    data: any[],
    userName: string,
    projectName: string
  ) {
    const wb = XLSX.utils.book_new();
    let sheetCounter = 1; // Counter to ensure unique sheet names

    data.forEach((item) => {
      console.log("data", item);
      const user_name = getUserNameByEmail(item.email);
      console.log("user_name", user_name);
      item.data.forEach((detail: any) => {
        const project_name = getProjectNameById(detail.projectId);
        if (detail.detail.length > 0) {
          let wsName = `${user_name}_${project_name}`.slice(0, 31);
          // Check if sheet with wsName already exists
          while (wb.Sheets[wsName]) {
            wsName = `${user_name}_${project_name}}_${sheetCounter++}`.slice(
              0,
              31
            );
          }

          const wsData = detail.detail.map((task: any) => ({
            Date: getUnixTime(task.date),
            Task: task.task,
            Hours: task.hour,
            Comment: task.comment,
          }));
          const ws = XLSX.utils.json_to_sheet(wsData);

          // Ensure ws.SheetNames is initialized as an array
          if (!ws.SheetNames) {
            ws.SheetNames = [];
          }

          // Add the wsName to SheetNames array
          ws.SheetNames.push(wsName);

          // Add metadata to the worksheet
          ws.Metadata = {
            userName,
            projectName,
          };

          XLSX.utils.book_append_sheet(wb, ws, wsName);
        }
      });
    });

    // Check if any sheets were added to the workbook
    if (wb.SheetNames.length > 0) {
      XLSX.writeFile(wb, `TimeSheet_${new Date().toISOString()}.xlsx`);
    } else {
    }
  }

  function buildQueryString(payload: any): string {
    const params = Object.keys(payload)
      .filter(
        (key) =>
          payload[key] !== undefined &&
          payload[key] !== "" &&
          payload[key] !== "All"
      )
      .map((key) => {
        const encodedKey = encodeURIComponent(key);
        let value = payload[key];

        // Convert projectId to a number
        if (key === "projectId") {
          value = Number(value);
        }
        const encodedValue = payload[key];
        return `${encodedKey}=${encodedValue}`;
      })
      .join("&");

    return `api/timesheet?${params}`;
  }

  useEffect(() => {
    methods.setValue("email", data[0].value);
    methods.setValue("project", project[0].value);
    methods.setValue("year", YearOption[0].value);
    methods.setValue("month", months[new Date().getMonth()].value);
  }, []);
  const onSubmit: SubmitHandler<any> = (payload: any) => {
    console.log("payload", payload);
    setPayload1(payload);
    payload.projectId = payload.project;
    delete payload.project;
    const queryString = buildQueryString(payload);
    console.log("urldata", queryString);
    apiService
      .get(queryString)
      .then((res) => {
        setTimesheet(res.data);
        if (!res.data.length) {
          //Checking for data is empty or not
          setMessage(true);
        }
        console.log("payload", payload);
        if (payload.email == "" && payload.projectId == "") {
        } else {
          setEnable(false);
        }

        console.log("res.data", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    message ? setEnable(false) : setEnable(true);
  }, [message]);

  const onExport = () => {
    if (timesheet && payload1) {
      const email = payload1.email || "";
      const projectId = payload1.projectId || ""; // Ensure projectId is defined
      const project1 = project.find((proj) => proj.value === projectId);
      const projectName = project1 ? project1.label : "All";
      const userName =
        email === ""
          ? "All"
          : data.find((user) => user.value === email)?.label || "";

      exportTimeSheetToExcel(timesheet, userName, projectName);
    }
  };

  const { t } = useTranslation();

  const BreadCrumbList = [
    { name: "Export Time Sheet", link: "#" },
    { name: "List", link: "#" },
  ];

  const handlClose = () => {
    setMessage(false);
  };

  return (
    <div>
      {/* Here Adding  page path and their grid title header  */}

      <BreadCrumb BreadCrumbList={BreadCrumbList}></BreadCrumb>

      <div className="flex gap-8 max-w-xl font-medium w-full rounded  p-4 m-4">
        <p className="w-full flex-1 rounded text-gray-600 text-xl p-2 px-2">
          {" "}
          Select users{" "}
        </p>
        <button
          className={`w-full flex-1 rounded shadow-md p-2 px-2 ${userActive === "users" ? "bg-green-500" : ""}`}
          onClick={() => {
            if (!loading) {
              setUserActive("users");
              setRefresh((prev) => prev + 1);
            }
          }}
        >
          Active Users
        </button>
        <button
          className={`w-full flex-1 rounded shadow-md px-5 ${userActive === "inactive-users" ? "bg-green-500" : ""}`}
          onClick={() => {
            if (!loading) {
              setUserActive("inactive-users");
              setRefresh((prev) => prev + 1);
            }
          }}
        >
          Inactive Users{" "}
        </button>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-center w-full gap-6">
            {/* <div className="grid gap-3 grid-cols-4 place-items-center	sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-4 2xl:grid-cols-6 "> */}
            <div className="mb-4 w-full sm:flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {" "}
                {t("User")}
              </label>
              <Select
                {...methods.register("email", { required: true })}
                options={data}
                placeholder="Select Employee"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("email", selectedOption.value);
                }}
                defaultValue={data[0]}
              />
              {methods.formState.errors.email && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.email.message}
                </span>
              )}
            </div>

            <div className="mb-4  w-full sm:flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {" "}
                {t("Projects")}
              </label>
              <Select
                {...methods.register("project", { required: true })}
                options={project}
                placeholder="Select Project"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("project", selectedOption.value);
                }}
                defaultValue={project[0]}
              />
              {methods.formState.errors.project && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.project.message}
                </span>
              )}
            </div>

            <div className="mb-4  w-full sm:flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {" "}
                {t("Year")}
              </label>
              <Select
                {...methods.register("year", { required: true })}
                options={YearOption}
                placeholder="Select Year"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("year", selectedOption.value);
                }}
                defaultValue={YearOption[0]}
              />
              {methods.formState.errors.year && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.year.message}
                </span>
              )}
            </div>

            <div className="mb-4  w-full sm:flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {" "}
                {t("Month")}
              </label>
              <Select
                {...methods.register("month", { required: true })}
                options={months}
                placeholder="Select month"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("month", selectedOption.value);
                }}
                defaultValue={months[new Date().getMonth()]}
              />
              {methods.formState.errors.month && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.month.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-center gap-5">
            <button
              className="justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium w-24 rounded-lg text-sm  py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="submit"
            >
              {t("View")}
            </button>

            <button
              className={`justify-center text-white ${enable ? `bg-gray-300` : `bg-blue-700`}   focus:ring-blue-300 font-medium rounded-lg text-sm w-24 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
              disabled={enable}
              onClick={onExport}
            >
              {t("Export")}
            </button>
          </div>
        </form>
      </FormProvider>
      {/* empty data pop-up message */}
      {timesheet && (
        <DataDisplay
          data={timesheet}
          userName={getUserNameByEmail}
          projectName={getProjectNameById}
        />
      )}
      {message && (
        <GenericModal
          isOpen={message}
          onClose={() => setMessage(false)}
          header="User Time sheet data"
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <p className="text-gray-500 text-xl mb-6 font-medium">
                Data is not exist{" "}
              </p>
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <button
                data-modal-hide="popup-modal"
                type="button"
                className="py-2.5 px-5 bg-red-400 hover:bg-red-700 ms-3 text-sm font-medium text-gray-900 focus:outline-none rounded-lg border border-gray-200  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={handlClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </GenericModal>
      )}
    </div>
  );
}
