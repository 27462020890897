export const reimbursementType = [
  { value: "Travel", label: "Travel" },
  { value: "Office", label: "Office" },
  { value: "Hardware", label: "Hardware" },
  { value: "Software", label: "Software" },
  { value: "Party", label: "Party" },
];

export const reimbursementUnitOption = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];
