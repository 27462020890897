/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import apiService from "services/apiService"; // Assuming you have an API service set up
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input";
import { FormProvider, useForm } from "react-hook-form";
import Button from "components/Button";
import { dateFormate } from "util/Util";
import Loader from "components/Loader";
import Select from "react-select";
import { sortByKey } from "util/sortOptions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { storageService } from "services/storageService";
import { useToaster } from "contexts/toasterContext";
import {
  FaClipboardList,
  FaPlay,
  FaCheckCircle,
  FaRegEdit,
} from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { BreadCrumb } from "components/BreadCrumb";
type Status = "To-do" | "In-progress" | "Completed";
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const statusIcons: Record<Status, JSX.Element> = {
  "To-do": <FaClipboardList className="text-gray-500" />,
  "In-progress": <FaPlay className="text-yellow-500" />,
  Completed: <FaCheckCircle className="text-green-500" />,
};
const validationSchema = yup.object({
  taskDetail: yup.string().required("Task Detail is required"),
  dueDate: yup.string().required("Due Date is required"),
  startDate: yup.string().required("Start Date is required"),
  assignedTo: yup.string().required("Assigned To is required"),
});

type Task = {
  taskDetail: string;
  dueDate: string;
  startDate: string;
  assignedTo: string;
  assignedBy: string;
  taskId: number;
  status: "To-do" | "In-progress" | "Completed"; // Kanban Board Statuses
};
interface OptionProps {
  value: string;
  label: string;
}

export default function TaskBoard() {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState<OptionProps | null>(null);
  const [data, setData] = useState<OptionProps[]>([]);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString());
  const [endDate, setEndDate] = useState<string>("");
  const da = storageService.getItem("user");
  const user = da && JSON.parse(da);
  const userRole = user?.role;
  const email = user?.email;
  const { addToast } = useToaster();
  const [selectedTab, setSelectedTab] = useState<"board" | "list">("board");

  useEffect(() => {
    if (userRole === 2) {
      apiService
        .get("api/users")
        .then((res) => {
          const getEmailOptions = (emailArray: any[]) => {
            return emailArray.map((email: any) => ({
              value: email.email,
              label: email.displayName,
            }));
          };
          const emailOptions = getEmailOptions(res.data);
          const userOption = sortByKey(emailOptions, "label");
          setData(userOption);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [userRole]);

  const fetchData = () => {
    if (userRole === 2) {
      apiService
        .get("api/task/getAll")
        .then((res) => {
          setTasks(res.data);
          setLoader(false);
        })
        .catch((err) => console.error("Error fetching tasks:", err));
    } else {
      apiService
        .get("api/task/get")
        .then((res) => {
          setTasks(res.data);
          setLoader(false);
        })
        .catch((err) => console.error("Error fetching tasks:", err));
    }
  };

  useEffect(() => {
    const today = new Date();
    setStartDate(formatDate(today));
    setLoader(true);
    fetchData();
  }, [userRole]);

  useEffect(() => {
    filterTasks();
  }, [tasks, startDate, endDate]);

  const filterTasks = () => {
    const filtered = tasks.filter((task) => {
      const taskDate = new Date(task.dueDate);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return (!startDate || taskDate >= start) && (!endDate || taskDate <= end);
    });

    setFilteredTasks(filtered);
  };

  // const updateTask = (task: Task, updatedTask: string) => {
  //   const data = { ...task, status: updatedTask };

  //   apiService
  //     .put(`api/task/update`, data)
  //     .then(() => {
  //       addToast(`Task Updated Successfully`, "success");
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       console.error("Error updating task:", err);
  //       addToast(`Something went Wrong`, "error");
  //     });
  // };

  const onSubmit = (data: any) => {
    const newData = { ...data, status: "To-do", assignedBy: email };
    apiService
      .post("api/task/add", newData)
      .then(() => {
        addToast(`Task Added Successfully`, "success");
        fetchData();
        setOpenModal(false);
      })
      .catch((err) => {
        console.error("Error creating task:", err);
        addToast(`Something went Wrong`, "error");
      });
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;

    // Check if destination is valid
    if (!destination) return;

    // If the task is dragged to the same column, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Find the task being dragged based on taskId
    const updatedTasks = Array.from(tasks);
    const movedTaskIndex = updatedTasks.findIndex(
      (task) => task.taskId.toString() === draggableId
    );

    // If task is not found, return
    if (movedTaskIndex === -1) return;

    const movedTask = updatedTasks[movedTaskIndex];

    // Update the task's status to the destination column's status
    const newStatus = destination.droppableId as Status;
    movedTask.status = newStatus;

    // Update the task in the backend via API
    apiService
      .put(`api/task/update`, movedTask) // Assuming the API accepts task object for update
      .then(() => {
        addToast(`Task status updated to ${newStatus}`, "success");
        fetchData(); // Fetch the updated list of tasks
      })
      .catch((err) => {
        console.error("Error updating task status:", err);
        addToast(`Error updating task status`, "error");
      });
  };

  const handleCancel = () => {
    methods.reset();
    setSelectedUser(null);
    setOpenModal(false);
  };

  const BreadCrumbList = [
    { name: "Task", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <div className="container mx-auto p-4">
      <BreadCrumb BreadCrumbList={BreadCrumbList}></BreadCrumb>
      <h1 className="text-lg font-bold mb-4">Task Management</h1>

      <div className="lg:flex justify-between mb-4">
        <div className="md:flex md:flex-row gap-4">
          <div className="md:flex items-center gap-4">
            <label className="text-sm font-medium text-gray-700">From:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="md:p-2 border mb-2 rounded w-full md:w-auto"
              placeholder="Start Date"
            />
          </div>
          <div className="md:flex items-center gap-4">
            <label className="text-sm font-medium text-gray-700">To:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="md:p-2 border mb-2 rounded w-full md:w-auto"
              placeholder="End Date"
            />
          </div>
        </div>

        {userRole === 2 && (
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 sm:mb-0 w-full lg:w-auto"
            onClick={() => setOpenModal(true)}
          >
            Create Task
          </button>
        )}
      </div>
      <div className="md:flex border-b border-gray-200 mb-4">
        <button
          className={`px-4 py-2 text-sm font-medium focus:outline-none ${selectedTab === "board" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
          onClick={() => setSelectedTab("board")}
        >
          Board View
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium focus:outline-none ${selectedTab === "list" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
          onClick={() => setSelectedTab("list")}
        >
          List View
        </button>
      </div>
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => handleCancel()}
          header="Create New Task"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Input name="taskDetail" required label="Task Details" />
              <Input name="startDate" type="date" required label="Start Date" />
              <Input name="dueDate" type="date" required label="Due Date" />
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Assign To
                </label>
                <Select
                  {...methods.register("assignedTo", { required: true })}
                  options={data}
                  placeholder="Select Employee"
                  className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      methods.setValue("assignedTo", selectedOption.value);
                      setSelectedUser(selectedOption);
                    }
                  }}
                  value={selectedUser}
                  required
                />
                {methods.formState.errors.assignedTo && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.assignedTo.message}
                  </span>
                )}
              </div>
              <Button type="submit">Submit</Button>
            </form>
          </FormProvider>
        </Modal>
      )}

      {!loader ? (
        selectedTab === "board" ? (
          <div className="container mx-auto p-4">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className=" grid grid-cols-1 lg:grid-cols-3 gap-6">
                {["To-do", "In-progress", "Completed"].map((status) => (
                  <Droppable key={status} droppableId={status}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="flex-shrink-0 border-inherit	border-solid	border-2 bg-white p-6 rounded-lg shadow-lg min-w-[320px]"
                      >
                        <div className="lg:flex md:items-center justify-between mb-4">
                          <div className="text-xl font-semibold text-gray-900 flex items-center gap-2 truncate">
                            {statusIcons[status as Status]} {status}
                          </div>
                          <span className="text-sm font-semibold text-gray-600 bg-gray-100 px-2 py-1 rounded-lg">
                            {
                              filteredTasks.filter(
                                (task) => task.status === status
                              ).length
                            }{" "}
                            Tasks
                          </span>
                        </div>

                        {filteredTasks
                          .filter((task) => task.status === status)
                          .map((task, index) => (
                            <Draggable
                              key={task.taskId}
                              draggableId={task.taskId.toString()}
                              index={index}
                            >
                              {(provided: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="bg-gradient-to-r from-gray-50 to-white mb-4 p-6 border border-gray-300 rounded-lg shadow-lg hover:shadow-xl hover:border-gray-400 transition-all duration-300 ease-in-out"
                                >
                                  <h4 className="font-semibold text-gray-800 flex items-center gap-2 text-lg">
                                    <FaRegEdit className="text-indigo-500" />
                                    <span className="break-words truncate  hover:text-clip">
                                      {task.taskDetail}
                                    </span>
                                  </h4>

                                  <p className="text-sm text-blue-700 mt-2 lg:flex lg:items-center gap-2">
                                    <MdDateRange className="text-teal-500" />
                                    <span className="font-medium">
                                      {dateFormate(task.startDate)}
                                    </span>{" "}
                                    -
                                    <span className="font-medium">
                                      {dateFormate(task.dueDate)}
                                    </span>
                                  </p>

                                  <p className="font-semibold text-gray-800 flex items-center gap-2 text-sm">
                                    <BiUser className="text-gray-500" />
                                    <span className="break-words truncate hover:text-clip sm:flex sm:flex-row items-start sm:items-center gap-2">
                                      {task.assignedTo}
                                    </span>
                                  </p>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </div>
        ) : (
          <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg">
            <div className="overflow-x-auto">
              <table className="bg-white table-auto min-w-full">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-left">Task</th>
                    <th className="py-2 px-4 text-left">Assigned To</th>
                    <th className="py-2 px-4 text-left">Start Date</th>
                    <th className="py-2 px-4 text-left">Due Date</th>
                    <th className="py-2 px-4 text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTasks.map((task) => (
                    <tr key={task.taskId} className="border-t border-gray-200">
                      <td className="py-2 px-4 break-words max-w-xs sm:max-w-md md:max-w-lg">
                        <span className="truncate block">
                          {task.taskDetail}
                        </span>
                      </td>
                      <td className="py-2 px-4">{task.assignedTo}</td>
                      <td className="py-2 px-4">
                        {dateFormate(task.startDate)}
                      </td>
                      <td className="py-2 px-4">{dateFormate(task.dueDate)}</td>
                      <td className="py-2 px-4">{task.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}
