/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import ReimbursementList from "./ReimbursementList";

import reimbursementManager from "services/Reimbursement/reimbursementManager";

import { FaEdit, FaEye, FaRegTrashAlt } from "react-icons/fa";
import UpdateReimbursement from "./updateReimbursement/UpdateReimbursement";
import GenericModal from "components/Modal";
import { ReimbursementResponse } from "services/Reimbursement/reimbursementInterface";
import ViewReimbursement from "./viewReimbursement/ViewReimbursement";
import DeletePopup from "components/DeletePopup/deletePopup";
import GenericToaster from "components/Toaster";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import { capitalize } from "util/Util";
// import { useAppContext } from "contexts";
import formatCurrency from "util/currency";
import { useAppContext } from "contexts/useAppContext";

export default function Reimbursement() {
  const [reimbursement, setReimbursement] = useState<any>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("");
  const { userRole } = useAppContext();
  const { t } = useTranslation();

  const handleEdit = (row: any): void => {
    setOpenEditModal(true);
    setSelectedRow(row);
  };
  const handleDelete = (row: any): void => {
    setDeleteModal(true);
    setSelectedRow(row);
  };

  const handleView = (row: any): void => {
    setViewModal(true);
    setSelectedRow(row);
  };

  const handleModalClose = () => {
    setOpenEditModal(false);
    setViewModal(false);
  };

  const onSuccess = (message: string) => {
    getReimbursementData();
    setSuccessToaster(true);
    setMessage(message);
  };

  const onError = (message: string) => {
    setErrorToaster(true);
    setMessage(message);
  };

  const deleteRow = (id: any) => {
    setDeleteModal(false);
    reimbursementManager
      .deleteReimbursement(id)
      .then((res) => {
        if (res.status === 200) {
          getReimbursementData();
          setSuccessToaster(true);
          setMessage("Deleted successfully");
        } else {
          setErrorToaster(true);
          setMessage(res.statusText || "Something went wrong");
        }
      })
      .catch((err) => {
        setErrorToaster(true);
        setMessage(err || "Something went wrong");
      });
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };

  const tableColumns = [
    // { key: "id", label: `ID`, bold: true },
    { key: "username", label: `UserName`, bold: true },
    { key: "type", label: `${t("reimbursement.type")}`, bold: true },
    {
      key: "spentDate",
      label: `${t("reimbursement.spentDate")}`,
      type: "Date",
      bold: true,
    },
    {
      key: "unit",
      label: `${t("reimbursement.unit")}`,
      bold: true,
      render: (row: any) => {
        return row === "INR" ? (
          <span dangerouslySetInnerHTML={{ __html: "&#x20B9;" }} />
        ) : (
          "$"
        );
      },
    },
    {
      key: "submitAmount",
      label: `Submit Amount`,
      render: (value: any, row: any) => {
        return `${formatCurrency(value, row.unit)}`;
      },
    },
    {
      key: "approveAmount",
      label: `${t("reimbursement.approveAmount")}`,
      bold: true,
      render: (value: any, row: any) => {
        return `${formatCurrency(value, row.unit)}`;
      },
    },
    {
      key: "differenceAmount",
      label: `${t("reimbursement.differenceAmount")}`,
      bold: true,
      render: (value: any, row: any) => {
        return `${formatCurrency(value, row.unit)}`;
      },
    },

    {
      key: "status",
      label: `${t("reimbursement.status")}`,
      bold: true,
      render: (row: any) => {
        if (row === "APPROVED") {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {capitalize(row)}
            </span>
          );
        } else if (row === "REJECTED") {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {capitalize(row)}
            </span>
          );
        } else if (row === "PENDING") {
          return (
            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
              {capitalize(row)}
            </span>
          );
        } else {
          return (
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-300 border border-blue-300">
              {capitalize(row)}
            </span>
          );
        }
      },
    },

    {
      key: "actions",
      label: `${t("reimbursement.actions")}`,
      excludeExport: true,
      actions: (row: any) => (
        <div className="flex flext-start">
          {userRole === 2 &&
            (row.status === "INITIATED" ||
              row.status === "PENDING" ||
              row.status === "APPROVED") && (
              <span
                onClick={() => handleEdit(row as ReimbursementResponse)}
                className="btn-primary px-2 py-2 mr-2 rounded-lg"
              >
                <FaEdit className="text-white" />
              </span>
            )}

          <span
            onClick={() => handleView(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
          >
            <FaEye className="text-white" />
          </span>
          {(row.status === "INITIATED" || row.status === "PENDING") && (
            <span
              onClick={() => handleDelete(row)}
              className="btn-red px-2 py-2 rounded-lg"
            >
              <FaRegTrashAlt className="text-white" />
            </span>
          )}
        </div>
      ),
    },
  ];

  const getReimbursementData = () => {
    setLoading(true);
    reimbursementManager
      .getAllReimbursementData()
      .then((res) => {
        if (res.status === 200) {
          console.log("res :>> ", res.data);
          const data = res.data.sort((a, b) => b.id - a.id);
          const ReimbursementResponses: any = data.map((row) => ({
            ...row,
          }));
          setLoading(false);
          setReimbursement(ReimbursementResponses);
        }
      })
      .catch((error) => {
        console.error("Error fetching Reimbursement list:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getReimbursementData();
  }, []);
  const BreadCrumbList = [
    { name: "Reimbursement", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <ReimbursementList
        data={reimbursement}
        columns={tableColumns}
        loading={loading}
        onSuccess={onSuccess}
        onError={onError}
        title="Reimbursement List"
      />
      {viewModal && (
        <ViewReimbursement
          selectedRow={selectedRow}
          columns={tableColumns}
          onClose={handleModalClose}
        />
      )}
      {openEditModal && (
        <GenericModal
          isOpen={openEditModal}
          onClose={handleModalClose}
          header="Update Reimbursement"
        >
          <UpdateReimbursement
            selectedRow={selectedRow}
            onClose={handleModalClose}
            onSuccess={onSuccess}
            onError={onError}
          />
        </GenericModal>
      )}
      {openDeleteModal && (
        <GenericModal
          isOpen={openDeleteModal}
          onClose={() => setDeleteModal(false)}
          header="Delete Reimbursement"
        >
          <DeletePopup
            handleDelete={deleteRow}
            onClose={() => setDeleteModal(false)}
            selectedRow={selectedRow}
          />
        </GenericModal>
      )}
      {(showSuccessToaster || showErrorToaster) && (
        <GenericToaster
          message={message}
          onClose={closeToaster}
          open={showSuccessToaster || showErrorToaster}
          severity={showErrorToaster ? "error" : "info"}
        />
      )}
    </>
  );
}
