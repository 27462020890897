import AdminLayout from "layouts/AdminLayout";
import DefaultLayout from "layouts/DefaultLayout";
import Timesheet from "pages/timesheet";
import UploadCsv from "pages/uploadcsv";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import DrawerExample from "../components/Drawer/Sample";
import Sidebar from "../components/SideBar";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import Invetories from "pages/invetory/Invetories";
import Timesheets from "pages/timesheetlist/Timesheets";
import EmployeeList from "pages/employee/EmployeeList";
import Holidays from "pages/holidays/Holidays";
import Leaves from "pages/leaves/Leaves";
import Projects from "pages/project/Projects";
import Reimbursement from "pages/reimbursement/Reimbursement";
import ErrorBoundary from "./ErrorBoundry";
import Loader from "components/Loader";
import Profile from "pages/profile";
import Rented from "pages/rentedInventory/Rented";
import SampleInventory from "pages/sampleInventory/SampleInvetory";
import ProtectedRoutes from "layouts/ProtectedRoutes";
import ViewTimeSheet from "pages/viewTimesheet";
import ExportTimeSheet from "pages/exportTimeSheet";
import GenerateDocs from "pages/generate-docs";
import TaskBoard from "pages/tasks/TaskBoard";
import Register from "pages/register/Registration";

export default function Routers() {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/:?token" element={<Login />}></Route>
              <Route path="/drawer" element={<DrawerExample />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/dashboard" element={<Sidebar />} />
            <Route
              path="/admin"
              element={<PrivateRoute Layout={AdminLayout} />}
            >
              <Route path="profile" element={<Profile />} />
              <Route path="timesheet" element={<Timesheet />} />
              <Route path="rented" element={<Rented />} />
              <Route path="sample" element={<SampleInventory />} />
              <Route path="timesheets" element={<Timesheets />} />
              <Route path="inventories" element={<Invetories />} />
              <Route path="register" element={<Register></Register>}></Route>
              <Route element={<ProtectedRoutes />}>
                <Route path="employees" element={<EmployeeList />} />
                <Route path="projects" element={<Projects />} />
                <Route path="upload" element={<UploadCsv />} />
              </Route>
              <Route path="holidays" element={<Holidays />} />
              <Route path="leaves" element={<Leaves />} />
              <Route path="tasks" element={<TaskBoard />} />
              <Route path="reimbursement" element={<Reimbursement />} />
              <Route path="view-timesheet" element={<ViewTimeSheet />} />
              <Route path="export-timesheet" element={<ExportTimeSheet />} />
              <Route path="generate-docs" element={<GenerateDocs />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
}
