import React, { ReactNode, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import Select from "react-select";
import apiService from "services/apiService";
import GenericModal from "components/Modal";

const schema = yup.object().shape({
  gender: yup.string().required(),
  designation: yup.string().required("designation Is Required."),
  displayName: yup.string().required("Employee Name is Required."),
  doj: yup.string().required(),
  email: yup.string().required("Email Is Required ."),
  mobileNumber: yup.string().required("Mobile Number Is Required ."),
});

interface AddProps {
  onClose: () => void;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}
interface GenderOption {
  value: string;
  label: string;
}

interface StoreResponseProp {
  projectNames: string[];
  assetModels: string[];
}
interface userOptions {
  value: number;
  label: string;
}
export default function EditEmployee({ onClose, data, onSuccess }: AddProps) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      doj: data.doj ? new Date(data.doj).toISOString().split("T")[0] : null,
    },
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const isUserStatusChanged =
    methods.getValues("userStatus") !== data.userStatus;

  const [storeResponse, setStoreResponse] = useState<StoreResponseProp>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: any) => {
    apiService
      .put("api/user", data)
      .then((res) => {
        console.log("res", res);
        res.status === 200 && setStoreResponse(res.data);
        onSuccess("Employee Details Updated successfully");
      })
      .catch((err) => {
        console.log("err :>> ", err);
      })
      .finally(() => {
        isUserStatusChanged ? "" : onClose();
      });
  };

  const userStatusteHandle = () => {
    const updatedStatus = methods.getValues("userStatus");
    const updatedUser = { ...data, userStatus: updatedStatus };
    console.log(updatedStatus);
    apiService
      .put("api/user?shouldRemoveUser=true", updatedUser)
      .then((res) => {
        if (res.status === 200) {
          onSuccess("User status updated successfully");
        } else {
          onSuccess("something is error");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        onClose();
      });
  };

  const genderOptions: GenderOption[] = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const options = [
    { value: "Web Developer", label: "Web Developer" },
    { value: "Java Developer", label: "Java Developer" },
    { value: "Developer", label: "Developer" },
    { value: "Windows developer", label: "Windows developer" },
    { value: "Programmer", label: "Programmer" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "Software engineer", label: "Software engineer" },
    { value: "Fullstack Developer", label: "Fullstack Developer" },
    { value: "HR manager", label: "HR manager" },
    { value: "Android Developer", label: "Android Developer" },
    { value: "IOS Developer", label: "iOS Developer" },
    { value: "QA Engineer", label: "QA Engineer" },
    { value: "Test Manager", label: "Test Manager" },
    { value: "Test Engineer", label: "Test Engineer" },
    { value: "Test Analyst", label: "Test Analyst" },
    { value: "Test Automation Engineer", label: "Test Automation Engineer" },
  ];
  const statusOptions: GenderOption[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const userOptions: userOptions[] = [
    { value: 1, label: "User" },
    { value: 2, label: "Admin" },
    { value: 3, label: "Manager" },
  ];
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-2">
            <Input
              name="displayName"
              label="Employee Name"
              placeholder={`Enter Full Name`}
            />
            <Input
              name="email"
              label="Email Address"
              placeholder={`Enter Email Address`}
            />
            <Input name="doj" label="Date Of Joining" type="date" />
            <Input
              name="mobileNumber"
              label="Mobile Number"
              placeholder={`Enter Mobile Number`}
              type="number"
            />
            <div className="mb-4">
              <label className="text-sm font-semibold"> Select Gender</label>
              <Select
                {...methods.register("gender", { required: true })}
                options={genderOptions}
                placeholder="Select Gender"
                className="w-full text-sm  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("gender", selectedOption.value);
                }}
                defaultValue={genderOptions.find(
                  (option) => option.value === data.gender
                )}
              />
              {methods.formState.errors.gender &&
                methods.formState.errors.gender.message && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.gender.message as ReactNode}
                  </span>
                )}
            </div>

            <div className="mb-4">
              <label className="text-sm font-semibold">
                {" "}
                Select Designation
              </label>
              <Select
                {...methods.register("designation", { required: true })}
                options={options}
                placeholder="Select Designation"
                className="w-full text-sm  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("designation", selectedOption.value);
                }}
                defaultValue={options.find(
                  (option) => option.value === data.designation
                )}
              />
              {methods.formState.errors.designation &&
                methods.formState.errors.designation.message && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.designation.message as ReactNode}
                  </span>
                )}
            </div>
            <div className="mb-4">
              <label className="text-sm font-semibold"> Select Status</label>
              <Select
                {...methods.register("userStatus", { required: true })}
                options={statusOptions}
                placeholder="Select Status"
                className="w-full  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("userStatus", selectedOption.value);
                }}
                defaultValue={statusOptions.find(
                  (option) => option.value === data.userStatus
                )}
              />
              {methods.formState.errors.userStatus &&
                methods.formState.errors.userStatus.message && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.userStatus.message as ReactNode}
                  </span>
                )}
            </div>
            <div className="mb-4">
              <label className="text-sm font-semibold"> Select Role</label>
              <Select
                {...methods.register("role", { required: true })}
                options={userOptions}
                placeholder="Select Role"
                className="w-full text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption &&
                    methods.setValue("role", selectedOption.value);
                }}
                defaultValue={userOptions.find(
                  (option) => option.value === data.role
                )}
              />
              {methods.formState.errors.role &&
                methods.formState.errors.role.message && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.role.message as ReactNode}
                  </span>
                )}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                if (data.userStatus !== methods.getValues("userStatus")) {
                  setIsUpdate(true);
                }
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
      {isUpdate && (
        <GenericModal
          isOpen={isUpdate}
          onClose={() => setIsUpdate(false)}
          header="Change User Status "
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <div className="rounded shadow p-4 mb-5 space-y-4 items-center justify-center">
                <h2 className="text-gray-600 font-medium text-xl">
                  Asset List{" "}
                </h2>
                <p className="text-gray-600 font-medium text-lg p-2">
                  Could you please confirm if you are collecting the following
                  asset models?{" "}
                </p>
                <span className="text-gray-500 font-medium text-lg">
                  {" "}
                  {storeResponse && storeResponse.assetModels
                    ? "He is responsible  for " +
                      storeResponse.assetModels.length +
                      ` Asset Models `
                    : ""}
                </span>
              </div>

              <div className="rounded shadow p-4 mb-5  items-center justify-center">
                <h2 className="text-gray-600 font-medium text-xl m-2">
                  Projects
                </h2>
                <span className="text-gray-600 font-medium text-lg p-2">
                  {" "}
                  The user has been removed from the project and will no longer
                  be involved.{" "}
                </span>
                <span className="text-gray-500 font-medium block text-lg">
                  {storeResponse && storeResponse.projectNames
                    ? "He has been assigned a total of " +
                      storeResponse.projectNames.length +
                      ` Projects `
                    : ""}
                </span>
              </div>

              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                onClick={() => {
                  userStatusteHandle();
                }}
              >
                Yes, Im sure
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => setIsUpdate(false)}
              >
                No, cancel
              </button>
            </div>
          </div>
        </GenericModal>
      )}
    </>
  );
}
