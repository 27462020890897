class AppConfig {
  constructor() {
    console.log("App config --->");
  }
  url = {
    production: {
      APIUrl: "https://asset.lirisoft.com/api/",
      LocalUrl: "https://asset.lirisoft.com/",
    },
    dev: {
      APIUrl: "http://ec2-18-189-200-115.us-east-2.compute.amazonaws.com:8080/",
      LocalUrl: "http://ec2-18-189-200-115.us-east-2.compute.amazonaws.com/",
    },
  };
  //testing url
  getBaseUrl(): string {
    const baseUrl =
      process.env.REACT_APP_BASE_URL || this.url.production.APIUrl;
    return baseUrl;
  }

  getLocalURL(): string {
    return this.url.production.LocalUrl;
  }

  googleLogin(): string {
    return `${this.getBaseUrl()}oauth2/authorization/google?redirect_uri=${this.getLocalURL()}`;
  }
}

const appConfig = new AppConfig();

export default appConfig;
