/* eslint-disable @typescript-eslint/no-explicit-any */
import appConfig from "services/apiConfig";
import axios from "axios";

declare module "axios" {
  interface AxiosInstance {
    deleteWithBody(url: string, data?: any): Promise<any>;
  }
}

const token = localStorage.getItem("accessToken");
const skillService = axios.create({
  baseURL: appConfig.getBaseUrl(),
});

// Adding a method to handle DELETE requests with data
skillService.deleteWithBody = (url: string, data: URLSearchParams) => {
  console.log("url", url);
  return axios.delete(appConfig.getBaseUrl() + url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export { skillService };
