/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import apiService from "services/apiService";
import { useToaster } from "contexts/toasterContext";
import Input from "components/Input";

interface DocName{
  documentName : string
  id : number
}

const schema = yup.object({
  documentName: yup.string().required("Document Name  is required"),
  file: yup.mixed().required("File is required"),
});
export default function UploadDocs({ refresh, setRefresh, setOpenModal }: any) {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToaster();
  const[dataName, setDataName] = useState<DocName[]>([])
  // const[name, setName] = useState<string>("");
   const[loading, setLoading] = useState(false); 


 const fetchData = () => {
    setLoading(true); // Set loading true before fetching data
    apiService
      .get("api/documents/templates")
      .then((res) => {
        // setLoading(false); // Set loading false after fetch is done
        console.log("res", res.data);
        setDataName(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      })
      .finally(()=>{
        setLoading(false)
      })
      
  };
useEffect(()=>{
  fetchData() ;
},[])

  const inputDocumentName = methods.getValues("documentName") ;
  console.log(inputDocumentName);

  const documentNameExists = dataName.some(
    (data) => data.documentName.toLowerCase() === inputDocumentName.toLowerCase()
  );

  const onSubmit = async (data: any) => {

    if(loading){
      return ;
    }

   if (documentNameExists) {
      addToast("Document name already exists", "error");
      return;
    }

    const formdata = new FormData();
    formdata.append("documentName", data.documentName);
    if (data.file[0]?.name) {
      formdata.append("file", data.file[0], data.file[0].name);
      apiService
        .post("api/documents/upload-template", formdata)
        .then((res) => {
          console.log("res", res.data);
          setOpenModal(false);
          addToast("Document Updated Successfully", "success");
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log("err", err);
          addToast("Something Went Wrong", "error");
        });
    } else {
      const result = await methods.trigger();
      console.log("result", result);
      if (!result) {
        console.log("Validation failed");
      }
    }
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="center">
          <div
            style={{ maxWidth: 540 }}
            className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 p-6 space-y-4 md:space-y-6 sm:p-8"
          >
            <Input name="documentName" label="Document Name" required placeholder="Please Enter the Document Name" />
            {documentNameExists && (
              <div className="text-red-500 text-sm">
                Document name already exists
              </div>
              )}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Upload File <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                {...methods.register("file")}
                required
              />
              {methods.formState.errors.file && (
                <p className="text-red-600">
                  {(methods.formState.errors.file.message as string) || "File is Required"}
                </p>
              )}
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white  bg-blue-700 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Upload
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
