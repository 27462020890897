import React, { useEffect, useState } from "react";
import apiService from "services/apiService";
import DocumentForm from "./DocumentForm";
import Modal from "components/Modal";
import { useToaster } from "contexts/toasterContext";

interface Doc {
  id: number;
  name: string;
  optional: boolean;
  tempDeleted: boolean;
}

const DocumentList: React.FC = () => {
  const [documents, setDocuments] = useState<Doc[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Doc | null>(null);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  // const [tempdelete,setTempdelete] = useState(false);
  const [id, setId] = useState(0);
  const { addToast } = useToaster();

  // Fetch document list
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const res = await apiService.get("api/documentNames/list");
      setDocuments(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments(); // Fetch documents on component mount
  }, []);

  // Delete document
  const deleteDocument =  async(id: number) => {
    console.log(id);
    setLoading(true);
    try {
  await  apiService.delete(`api/documentNames/${id}`);
      addToast(` document ${ id } is deleted successFully `, "success");
      fetchDocuments(); // Refresh the document list after deletion
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle edit click
  const handleEdit = (doc: Doc) => {
    setSelectedDocument(doc); // Pass the selected document to the form
  };

  const handleDelete = (docId: number) => {
    setId(docId);

    setIsDelete(true);
  };

  return (
    <div className="p-6">
      {loading && <p>Loading...</p>}
      {/* <h2 className="text-xl font-semibold mb-6">Document List</h2> */}
      <div className="grid grid-cols-1 md:grid-cols-[70%,30%] gap-4">
        <DocumentForm
          selectedDocument={selectedDocument}
          refreshDocuments={fetchDocuments}
          setSelectedDocument={setSelectedDocument}
        />
        <h2 className="text-xl font-semibold mb-6">Document List</h2>
        <table className="min-w-full mt-4 border bg-white shadow rounded-lg">
          <thead className="bg-gray-100">
            <tr>
              <th className="border px-4 py-2 text-center">Document Name</th>
              <th className="border px-4 py-2 text-center">Optional</th>
              <th className="border px-4 py-2 text-center">Temp Delete</th>
              <th className="border px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc.id} className="hover:bg-gray-50">
                <td className="border px-4 py-2 text-center">{doc.name}</td>
                <td className="border px-4 py-2 text-center">
                  {doc.optional ? "Yes" : "No"}
                </td>
                <td className="border px-4 py-2 text-center">
                  {doc.tempDeleted ? "Yes" : "No"}
                </td>
                <td className="border px-4 py-2 text-center space-x-4">
                  <button
                    onClick={() => handleEdit(doc)}
                    className="text-blue-600 hover:text-blue-800 transition"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(doc.id)}
                    className="text-red-600 hover:text-red-800 transition"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDelete && (
        <Modal
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          header="Delete"
        >
          <p>Are sure About Delete</p>
          <button
            onClick={() => {
              deleteDocument(id);
              setIsDelete(false);
            }}
            className="bg-blue-400 p-2 rounded px-5 shadow hover:bg-blue-700 text-lg"
          >
            I am, sure
          </button>

          <button
            onClick={() => {
              setIsDelete(false);
            }}
            className="bg-red-400 p-2 absolute right-6 px-5 rounded shadow hover:bg-red-800 text-lg"
          >
            {" "}
            Cancel{" "}
          </button>
        </Modal>
      )}
    </div>
  );
};

export default DocumentList;
