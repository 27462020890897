import React from "react";
import Input from "components/Input";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import apiService from "services/apiService";
import { BreadCrumb } from "components/BreadCrumb";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "contexts/toasterContext";

const validSchema = yup.object().shape({
  displayName: yup.string().required("User Name Required "),
  email: yup.string().required("User Email Required "),
  password: yup
    .string()
    .min(6, "Required Minimum 6 Characters")
    .required("Password is  Required "),
  matchPassword: yup
    .string()

    .oneOf([yup.ref("password")], "Passwords must be match"),

  socialProvider: yup.string(),
});

const Register = () => {
  const methods = useForm({
    resolver: yupResolver(validSchema),
  });

  const { addToast } = useToaster();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (data: any) => {
    console.log(data);

    const newdata = { ...data, socialProvider: "LOCAL" };

    apiService
      .post("api/user/new", newdata)
      .then((res) => {
        console.log(res);
        addToast("User Register Is Success ", "success");
      })
      .catch((error) => {
        console.error(error);
        addToast("Register is Failed", "error");
      });

    resetFun();
  };
  const BreadCrumbList = [
    { name: "Registration ", link: "#" },
    { name: "User Registration ", link: "#" },
  ];

  const resetFun = () => {
    methods.setValue("displayName", ""),
      methods.setValue("email", ""),
      methods.setValue("password", ""),
      methods.setValue("matchPassword", "");
  };

  return (
    <FormProvider {...methods}>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div>
        <h1 className="center text-2xl m-3 p-2 font-medium ">
          Registration Form
        </h1>
      </div>
      <form
        className="space-y-8 m-6 p-3"
        onSubmit={methods.handleSubmit(submit)}
      >
        <div>
          <Input
            name={"displayName"}
            label={"User Name : "}
            placeholder="Enter User Name"
            required
          ></Input>
        </div>
        <Input
          type="email"
          name={"email"}
          label="Email  "
          placeholder="Enter Email"
          required
        ></Input>
        <Input
          type="password"
          name={"password"}
          label="Password : "
          placeholder="Enter Password "
          required
        ></Input>
        <div>
          <Input
            type="password"
            name={"matchPassword"}
            label="Match Password : "
            placeholder="Enter Macthing Password "
            required
          ></Input>
        </div>

        <div className="flex gap-12">
          <button
            type="submit"
            className="bg-blue-400 w-96 p-3 rounded-lg shadow hover:bg-blue-800"
          >
            Submit{" "}
          </button>
          <button
            type="reset"
            className="bg-red-400 w-96 rounded-lg shadow hover:bg-red-800"
            onClick={resetFun}
          >
            Reset{" "}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Register;
