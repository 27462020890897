/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye } from "react-icons/fa";
import Modal from "components/Modal";
import EmployeeDetails from "./EmployeeDetails";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import GenericToaster from "components/Toaster";
// import { render } from "@testing-library/react";
// import { error } from "console";

interface ProjecProp {
  
    projectId:number
     name : string     
     manager : string
     teamSize : string
     startDate : string
     completionDate : string
     clientContactName : string
     clientContactNumber : string
     projectDetail : string
      emails :string
     projectAssignedDate : string
    }

  interface ResponseProps {
    projectNames :string[]
    assetModels : string[]
  }


export default function EmployeeList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addEmployee, setAddEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [userActive, setUserActive] = useState("users");
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("");
  const [changeUserStatus, setChangeUserStatus] = useState(false);
  const[currentStatusValue , setCurrentStatusValue] = useState<any>(null);
  const [isCollectAssets, setIsCollectAssets] = useState(false);
  const[isClickProject , setIsClickProject] = useState(false) ;
const[projects  , setProjects] = useState<ProjecProp[]>([]) ;
const[isResponse, setIsResponse] = useState<ResponseProps>();

  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    apiService
      .get(`api/${userActive}`)
      .then((res) => {
        const newData = res.data.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
        setData(newData);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, userActive]);

  const handleStatusClick = (value : any) =>{
    console.log("inside handle click vaklue >>> ::: "+value);
    setChangeUserStatus(true);
    setCurrentStatusValue(value);
    
  }
// This Function is Updating UserStatus From ACTIVE to INACTIVE And Vice-Versa

  const userStatusteHandle = async (store:string) =>{
    console.log("current staatus >>> : "+currentStatusValue);
    console.log("store url :::"+store)
    if(!currentStatusValue) {
      return ;
    }
        console.log(currentStatusValue.userStatus);
          const updatedStatus = currentStatusValue.userStatus === "ACTIVE"?"INACTIVE": "ACTIVE";
          const updatedUser  =  {...currentStatusValue, userStatus : updatedStatus}
      await apiService
      .put(store, updatedUser)
      .then(res =>{
        setIsResponse(res.data) ;
      })
      .catch(error =>{
        console.error(error);
        handleError("Something is wrong" + error);
      })
  }
      // ------------------------------------------------
      
      const userShouldTrue = async (store:string) =>{   
    if(!currentStatusValue) {
      return ;
    }
        console.log(currentStatusValue.userStatus);
        const updatedStatus = currentStatusValue.userStatus === "ACTIVE"?"INACTIVE": "ACTIVE";
        const updatedUser  =  {...currentStatusValue, userStatus : updatedStatus}
        console.log(updatedStatus);
    await apiService
      .put(store, updatedUser)
      .then(res =>{
        console.log("response >>>>>> ::::"+res);
        if(res.status === 200)
        {
          handleSuccess("Employee Updated successFully");
          setRefresh(previous => previous+1);
        }else{
          handleError("Something went Wrong While Updating status")
        }
      })
      .catch(error =>{
        console.error(error);
        handleError("Something is wrong" + error);
      })
      .finally(()=>
      {
        setChangeUserStatus(false);
        setCurrentStatusValue(null);
        // setIsCollectAssets(false)
      })
  }
      // -----------------------------------
  const tableColumns = [
    // { key: "id", label: `Id`, bold: true },
    { key: "displayName", label: `${t("employees.name")}`, bold: true },
    { key: "email", label: `${t("employees.email")}` },
    {
      key: "mobileNumber",
      label: `${t("employees.mobileNumber")}`,
      bold: true,
    },
    {
      key: "userProjectWithAssignedDate",
      label: `Projects`,
      excludeExport: true,
      // eslint-disable-next-line
      render: (row: any) => (
        <>
          {row ? (
            <button
             className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400"
             onClick={()=>{setIsClickProject(true);
              setProjects(row)
             }}
             >
              {row.length ? row.length : 0}
            </button>
          ) : (
            <button
             className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400"
              onClick={()=>{setIsClickProject(true)}}
             >
              0
            </button>
          )}
        </>
      ),
    },
    {
      key: "role",
      label: `${t("employees.role")}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any) => {
        if (row === 3) {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {t("employees.manager")}
            </span>
          );
        } else if(row === 2){
          return (
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              {t("employees.admin")}
            </span>
          );
        } else {
          return(
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              {t("employees.user")}
            </span>
          )
        }
      },
    },
    {
      key: "userStatus",
      label: `${t("employees.userStatus")}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any, value : any) => {
        if (row === "ACTIVE") {
          return (
            <button onClick={() =>handleStatusClick(value)} className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {row}
            </button>
          );
        } else {
          return (
            <button onClick={() => handleStatusClick(value)} className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {row}
            </button>
          );
        }
      },
    },
    {
      key: "actions",
      label: `${t("employees.actions")}`,
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          <span onClick={() => handleEdit(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEdit className="text-white" />
          </span>
          <span onClick={() => handleView(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEye className="text-white" />
          </span>
        </div>
      ),
    },
  ];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleView = (row: any): void => {
    console.log("row", row);
    setViewData(row);
    setOpenModal(true);
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleEdit = (row: any): void => {
    setViewData(row);
    setEditEmployee(true);
  };

  const BreadCrumbList = [
    { name: "Employees", link: "#" },
    { name: "List", link: "#" },
  ];
  const handleEditEmployeeClose = () => {
    setEditEmployee(false);
    setRefresh((prevKey) => prevKey + 1);
  };
  const addEditEmployeeClose = () => {
    setAddEmployee(false);
    setRefresh((prevKey) => prevKey + 1);
  };

  const handleSuccess = (message: string) => {
    setMessage(message);
    setSuccessToaster(true);
    
  };

  const handleError = (message: string) => {
    setMessage(message);
    setErrorToaster(true);
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };

   const userStatusCancel = () =>{
    setChangeUserStatus(false);
     setRefresh((prevKey) => prevKey + 1);
   }

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className="flex space-x-4">
        <button
          className={`py-2 rounded-lg px-8 border  ${userActive === "users" ? "bg-blue-700 text-white" : "bg-white"}`}
          onClick={() => {
            if(!loading){
              setUserActive("users");
            setRefresh(prev => prev+1);
            }
          }
          }
        >
          {/* Active Employees */}
          {`${t("Active")} ${t("Employees")}`}
        </button>
        <button
          className={`py-2 rounded-lg px-8 border  ${userActive === "inactive-users" ? "bg-blue-700 text-white" : "bg-white"}`}
          onClick={() => {
           if(!loading){
             setUserActive("inactive-users");
            setRefresh(prev => prev + 1);
           } 
           
          }}
        >
          {/* Inactive Employees */}
          {`${t("Inactive")} ${t("Employees")}`}
        </button>
      </div>
      {data && (
        <DataTable
          data={data}
          columns={tableColumns}
          loading={loading}
          // title={userActive === "users" ? "A   ctive Employees List" : "Inactive Employees List"}
         
          title={userActive === "users" ? `${t("Active")} ${t("Employees")} ${t("List")}` : `${t("Inactive")} ${t("Employees")} ${t("List")}`}
        />
      )}
      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="View Employee Details">
          {viewData && <EmployeeDetails data={viewData} />}
        </Modal>
      )}
      {changeUserStatus && (
        <Modal isOpen={changeUserStatus} onClose={() => setChangeUserStatus(false)} header="Change User Status">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to Change User Status?
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                onClick={()=>{
                  userStatusteHandle("api/user");
                  setIsCollectAssets(true) ;

                }}
              >
                Yes, Im sure
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={userStatusCancel}
              >
                No, cancel
              </button>
            </div>
          </div>
        </Modal>
      )}

      {
        isCollectAssets && <Modal isOpen={isCollectAssets} onClose={() => setIsCollectAssets(false)} header="Asset List">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
             
             <div className="rounded shadow p-4 mb-5 space-y-4 items-center justify-center">
                <h2 className="text-gray-600 font-medium text-xl">Asset List </h2>
                <p className="text-gray-600 font-medium text-lg p-2">Could you please confirm if you are collecting the following asset models? </p>
                <span className="text-gray-500 font-medium text-lg"> {isResponse && isResponse.assetModels? 'He is responsible  for '+ isResponse.assetModels.length +` Asset Models `:""}</span>
              </div>

              <div className="rounded shadow p-4 mb-5  items-center justify-center">
                 <h2 className="text-gray-600 font-medium text-xl m-2">Projects</h2>
                 <span className="text-gray-600 font-medium text-lg p-2"> The user has been removed from the project and will no longer be involved. </span>
                 <span className="text-gray-500 font-medium block text-lg">{isResponse && isResponse.projectNames?  'He has been assigned a total of '+ isResponse.projectNames.length +` Projects `:""}</span>
             </div>
             
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                onClick={()=>{
                  userShouldTrue(`api/user?shouldRemoveUser=true`);
                  setIsCollectAssets(false) ;

                }}
              >
                Yes, Im sure
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={()=>setIsCollectAssets(false)}
              >
                No, cancel
              </button>
            </div>
          </div>
        </Modal>
      }

      {(isClickProject) &&(projects.length > 0)&& (
        <Modal isOpen={isClickProject} onClose={() => setIsClickProject(false)} header="User Project List ">
        <div className="rounded shadow-md p-3 m-3">
          <ul className={`grid ${projects.length > 20?`grid-cols-3 `:`grid-cols-2`} grid-cols-2 items-center p-2 justify-center space-y-5 overflow-hidden`}>
            {
            projects && projects.map((project)=><li key={project.projectId} className="text-gray-500 basis-1 text-xl font-medium m-3">  {project.name}</li>)
          }
          </ul>
        </div>
        </Modal>
      )}

      {addEmployee && (
        <Modal isOpen={addEmployee} onClose={addEditEmployeeClose} header="Add Employee">
          <AddEmployee onClose={addEditEmployeeClose} />
        </Modal>
      )}
      {editEmployee && (
        <Modal isOpen={editEmployee} onClose={handleEditEmployeeClose} header="Edit Employee">
           <EditEmployee onClose={handleEditEmployeeClose} data={viewData} onSuccess={handleSuccess} onError={handleError} />

</Modal>
)}

{(showSuccessToaster || showErrorToaster) && (
  <GenericToaster
    message={message}
    onClose={closeToaster}
    open={showSuccessToaster || showErrorToaster}
    severity={showErrorToaster ? "error" : "info"}
  />
)}

    </>
  );
}
