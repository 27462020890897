import React from "react";
import ImageList from "./ImageList";
import { dateFormate } from "util/Util";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ViewProduct = (product: any) => {
  const { assign, type, status, owner, location, model, brand, assignDate, urls } = product.product;
  return (
    <>
      {product && (
        <div className="max-w-xl mx-auto bg-white p-4 rounded-md shadow-md mb-4">
          {urls.length > 0 && <ImageList images={urls} />}
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold text-gray-800">{assign}</h2>
            <p className="text-sm text-gray-500">
              <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                {type === "IPhone"?"iPhone":type}
              </span>
            </p>
          </div>
          <p className=" font-bold mb-4">{model}</p>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-lg font-semibold text-gray-700">Status</p>
              <p className="text-gray-800 ">{status}</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-700">Owner</p>
              <p className="text-gray-800">{owner}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-lg font-semibold text-gray-700">Location</p>
              <p className="text-gray-800">{location}</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-700">Brand</p>
              <p className="text-gray-800">{brand}</p>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-lg font-semibold text-gray-700">Assigned Date</p>
            <p className="text-gray-800">{dateFormate(assignDate)}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewProduct;
