/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/en-gb";
import Select from "react-select";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import Modal from "components/Modal";
import apiService from "services/apiService";
import CustomToolbar from "./CustomToolbar";
import EventInfo from "./EventInfo";
import Tooltip from "components/Tooltip";
import { BreadCrumb } from "components/BreadCrumb";
const Localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
import { useTranslation } from "react-i18next";
import { useAppContext } from "contexts";
import ApproveTimesheet from "./ApproveTimesheet";
import { sortByKey } from "util/sortOptions";

const ViewTimeSheet: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [events, setEvents] = useState<any>();
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [eventDetailsModal, setEventDetailsModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [projects, setProjects] = useState<any>([]);
  const [wholeData, setWholeData] = useState();
  const { t } = useTranslation();
  const { user, userRole } = useAppContext();
  const [refresh, setRefresh] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [hours, setHours] = useState(0);

  const handleProjectChange = (selectedOption: any) => {
    setSelectedProject(selectedOption.value);
  };

  const handleUserChange = (selectedOption: any) => {
    setSelectedProject(null);
    setProjects(null);
    setSelectedUser(selectedOption.value.email);
    setProjects(selectedOption?.value?.userProjectWithAssignedDate);
  };
  function removeDuplicates(arr: any) {
    const map = new Map();

    arr.forEach((entry: any) => {
      const key = `${entry.title}-${entry.projectId}-${entry.hour}-${entry.start}`;
      if (!map.has(key)) {
        map.set(key, entry);
      }
    });

    return Array.from(map.values());
  }

  const handleEventSelect = (event: any) => {
    setSelectedEvent(event);
    setEventDetailsModal(true);
  };
  const projectOptions: { value: any; label: any }[] =
    projects && projects.length > 0
      ? projects.map((project: any) => ({
          value: project.projectId,
          label: project.name,
        }))
      : [];
  const tempUsers =
    users &&
    users.map((res: any) => ({
      value: res,
      label: res.displayName,
    }));
  const userOption = sortByKey(tempUsers, "label");

  const onSelectSlot = useCallback((e: any) => {
    setSelectedDate(e.start);
    // setOpenModal(true);
  }, []);
  const dayPropGetter = useCallback(
    (date: Date) => ({
      ...((moment(date).day() === 6 || moment(date).day() === 0) && {
        style: {
          backgroundColor: "#f6f6f6",
          // color: "red ",
        },
      }),
    }),
    []
  );
  const handleNavigate = (date: Date) => {
    console.log("Current month:", moment(date).format("MMM"));
    console.log("Current year:", moment(date).format("YYYY"));
    setCurrentMonth(`${moment(date).format("MMM")}`);
    setCurrentYear(moment(date).format("YYYY"));
  };
  const handleView = () => {
    const currentDate = new Date();
    setCurrentMonth(`${moment(currentDate).format("MMM")}`);
    setCurrentYear(moment(currentDate).format("YYYY"));
  };

  useEffect(() => {
    handleView();
  }, []);
  const getTimeSheetData = (payload: any) => {
    const queryParams = new URLSearchParams({
      year: payload.year.toString(),
      month: payload.month,
      ...(payload.projectId && { projectId: payload.projectId.toString() }),
      ...(payload.email && { email: payload.email }),
    });
    const url = `api/timesheet?${queryParams.toString()}`;
    console.log("url => ", url);
    return apiService.get(url);
  };
  useEffect(() => {
    const payload = {
      year: currentYear,
      month: currentMonth,
      email: selectedUser,
      projectId: selectedProject,
    };
    console.log("payload", payload);
    if (selectedUser) {
      if (payload.year) {
        getTimeSheetData(payload)
          .then((res) => {
            console.log("res.data", res.data[0]);
            setHours(res.data[0].totalHour);
            if (res.data.length > 0) {
              setWholeData(res.data[0]);
              const timesheetdata: any[] = [];
              const AA = res.data;
              console.log("res.data.month :>> ", res.data[0].month);
              const monthString = res.data[0].month;
              const monthIndex = moment().month(monthString).month();
              if (AA.length > 0) {
                const eventData = AA[0].data;
                console.log("eventData :>> ", eventData);
                eventData &&
                  eventData.map((val: any, i: number) => {
                    if (eventData) {
                      const details = eventData[i].detail;
                      const event = details
                        .filter((detail: any) => detail.task.trim() !== "")
                        .map((detail: any) => ({
                          id: detail.id,
                          title: detail.task,
                          projectId: eventData[i].projectId,
                          hour: detail.hour,
                          // start: new Date(detail.day),
                          // end: new Date(detail.day),
                          start: moment()
                            .set({
                              date: detail.day,
                              month: monthIndex,
                              // hour: 9,
                              year: moment.unix(detail.date).utc().year(),
                            })
                            .toDate(),
                          end: moment()
                            .set({
                              date: detail.day,
                              month: monthIndex,
                              // hour: 19,
                              year: moment.unix(detail.date).utc().year(),
                            })
                            .toDate(),
                          allDay: true,
                        }));
                      timesheetdata.push(...event);
                    }
                  });
                console.log("timesheetdata :>> ", timesheetdata);
              }
              const uniqueArray = timesheetdata.filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.title === item.title &&
                      t.hour === item.hour &&
                      t.start === item.start
                  )
              );
              console.log("uniqueArray :>> ", uniqueArray);
              const uniqueEntries = removeDuplicates(uniqueArray);
              setEvents(uniqueEntries);
            } else {
              setEvents([]);
              // setWholeData();
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  }, [currentYear, currentMonth, selectedProject, selectedUser, refresh]);

  const eventPropGetter = (event: any) => {
    const color = generateDarkColor(event.projectId);
    return { style: { backgroundColor: color } };
  };

  function generateDarkColor(projectId: number): string {
    const colorSeed = (projectId * 1031) % 360;
    const adjustedColor = `hsl(${colorSeed}, 50%, 20%)`;
    return adjustedColor;
  }
  useEffect(() => {
    apiService
      .get("/api/users")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("time sheet err", err);
      });
  }, []);
  const CustomEvent = ({ event }: any) => (
    <Tooltip content={event.title}> {event.title}</Tooltip>
  );
  const BreadCrumbList = [
    { name: "Timesheeet", link: "#" },
    { name: "View TimeSheet", link: "#" },
  ];
  // useEffect(() => {
  //   if (userRole) {
  //     if (userOption && user) {
  //       const data = userOption.find((option) => option.value.email === user.email);
  //       console.log("data :>> ", data?.value.email);
  //       setSelectedUser(data?.value.email);
  //       if (data) {
  //         // setSelectedProject(data.value);
  //         setProjects(data?.value?.userProjectWithAssignedDate);
  //       }
  //     }
  //   }
  // }, [userOption]);
  const [approve, setApprove] = useState(false);
  console.log("events", events);
  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className=" bg-gray-50 p-4 ">
        {selectedEvent && eventDetailsModal && (
          <Modal
            isOpen={eventDetailsModal}
            onClose={() => setEventDetailsModal(false)}
            header="Timesheet Info"
          >
            <EventInfo selectedEvent={selectedEvent} projects={projects} />
          </Modal>
        )}
        {approve && (
          <Modal
            isOpen={approve}
            onClose={() => setApprove(false)}
            header="Approve Timesheet"
          >
            <ApproveTimesheet
              projects={projects}
              setApprove={setApprove}
              selectedUser={selectedUser}
              currentYear={currentYear}
              currentMonth={currentMonth}
            />
          </Modal>
        )}

        <div className="between">
          <div
            style={{
              marginLeft: 25,
            }}
          >
            {hours > 0 && (
              <h2 className="text-blue-600 bold">Total Hours : {hours}</h2>
            )}
          </div>
          <div className="flex justify-end  ">
            {userRole && (
              <>
                {users && (
                  <div className="me-2 w-64	" style={{ zIndex: 9 }}>
                    <Select
                      name="user"
                      options={userOption}
                      value={userOption.find(
                        (option) => option.value === selectedUser
                      )}
                      onChange={handleUserChange}
                      placeholder={t("timesheet.selectuser")}
                      className="select"
                      // defaultValue={userOption.find((option) => option.value.email === user.email)}
                    />
                  </div>
                )}
              </>
            )}
            <div className="me-2 w-64	hidden" style={{ zIndex: 99 }}>
              <Select
                name="project"
                options={projectOptions}
                value={projectOptions.find(
                  (option) => option.value === selectedProject
                )}
                onChange={handleProjectChange}
                placeholder={t("timesheet.selectproject")}
                className="select"
                isDisabled={true}
                // isDisabled={!selectedUser}
                // defaultValue={projects?.value?.userProjectWithAssignedDate}
                // isMulti
              />
            </div>
            <div>
              <button
                onClick={() => {
                  setApprove(true);
                }}
                type="button"
                className={`text-white  dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${selectedUser ? "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" : "bg-gray-400"}`}
                disabled={selectedUser ? false : true}
              >
                Approve Timesheet
              </button>
            </div>
          </div>
        </div>
        <div className="p-4">
          <DragAndDropCalendar
            views={["month", "agenda"]}
            selectable
            localizer={Localizer}
            events={events}
            onSelectEvent={(event) => handleEventSelect(event)}
            style={{ height: 500 }}
            onSelectSlot={onSelectSlot}
            defaultDate={new Date()}
            defaultView="month"
            resizable
            components={{
              toolbar: CustomToolbar,
              agenda: {
                event: CustomEvent,
              },
            }}
            popup
            dayPropGetter={dayPropGetter}
            onNavigate={handleNavigate}
            onView={handleView}
            // eventPropGetter={eventPropGetter}
          />
        </div>
      </div>
    </>
  );
};

export default ViewTimeSheet;
