/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUnixTime } from "util/epochTime";

type Detail = {
  date?: any;
  id: number;
  day: number;
  task: string;
  hour: number;
  comment: string;
};

type ProjectData = {
  id: number;
  projectId: number;
  detail: Detail[];
  comment: string;
  status: string;
  totalHour: number;
};

type UserData = {
  id: number;
  userName: string;
  email: string;
  year: number;
  month: string;
  status: string;
  projectIds: string;
  submitDate: string;
  totalHour: number;
  comment: string;
  data: ProjectData[];
};

type DataDisplayProps = {
  data: UserData[];
  userName?: any;
  projectName?: any;
};

const DataDisplay = ({ data, userName, projectName }: DataDisplayProps) => {
  const [expandedUserIds, setExpandedUserIds] = useState<number[]>([]);

  const toggleExpand = (userId: number) => {
    setExpandedUserIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };
  const { t } = useTranslation();
  return (
    <div className="mt-8">
      <header className="bg-gray-800 text-white p-4 shadow-lg">
        <h1 className="text-3xl font-bold">User Data</h1>
      </header>
      <div className="container mx-auto p-4">
        {data.map(
          (user) =>
            user.data.length > 0 && (
              <div
                key={user.id}
                className="bg-white shadow-md rounded-lg p-6 mb-6 
                
                border-l-4 border-blue-500 transition-transform transform hover:scale-105"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">
                      {userName(user.email)}
                    </h2>
                    <p className="text-green-600">
                      Total Hours : <strong> {user.totalHour} </strong>
                    </p>
                  </div>

                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => toggleExpand(user.id)}
                  >
                    {expandedUserIds.includes(user.id) ? "Minimize" : "Expand"}
                  </button>
                </div>
                {expandedUserIds.includes(user.id) && (
                  <>
                    <div className="flex flex-wrap mb-4">
                      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                        <p className="text-gray-600">
                          <strong>Year:</strong> {user.year}
                        </p>
                      </div>
                      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                        <p className="text-gray-600">
                          <strong>Month:</strong> {user.month}
                        </p>
                      </div>
                      <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                        <p
                          className={`text-gray-600 ${
                            user.status === "InProgress"
                              ? "text-yellow-500"
                              : "text-green-500"
                          }`}
                        >
                          <strong>Status:</strong> {user.status}
                        </p>
                      </div>
                      {/* <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                        <p className="text-gray-600">
                          <strong>Total Hours:</strong> {user.totalHour}
                        </p>
                      </div> */}
                      <div className="w-full p-2">
                        <p className="text-gray-600 mb-4">
                          <strong>Comment:</strong> {user.comment}
                        </p>
                      </div>
                    </div>
                    {user.data
                      .filter((project) => project.totalHour > 0) // Filter out projects with totalHour 0
                      .map(
                        (project) =>
                          project.detail.length > 0 && (
                            <div
                              key={project.id}
                              className="bg-gray-50 p-4 rounded-lg shadow-inner mb-4 transition-transform transform hover:scale-105"
                            >
                              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                Project Name: {projectName(project.projectId)}
                              </h3>
                              {/* <p className="text-gray-600">
                                <strong>Status:</strong> {project.status}
                              </p> */}
                              <p className="text-gray-600">
                                <strong>{t("Total Hours:")}</strong>{" "}
                                {project.totalHour}
                              </p>
                              <table className="min-w-full mt-2">
                                <thead>
                                  <tr>
                                    <th className="py-2  px-4 bg-gray-200 text-gray-700 	">
                                      Date
                                    </th>
                                    <th className="py-2 min-w-96	 px-4 bg-gray-200 text-gray-700">
                                      Task
                                    </th>
                                    <th className="py-2 px-4 bg-gray-200 text-gray-700">
                                      Hours
                                    </th>
                                    {/* <th className="py-2 px-4 bg-gray-200 text-gray-700">Comment</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {project.detail
                                    .filter((detail) => detail.hour > 0) // Filter out details with hour 0
                                    .map((detail) => (
                                      <tr
                                        key={detail.id}
                                        className="border-b last:border-0"
                                      >
                                        <td className="border w-32	 px-4 py-2 text-gray-600">
                                          {detail.date
                                            ? getUnixTime(detail.date)
                                            : detail.day}
                                        </td>
                                        <td className="border min-w-96 px-4 py-2 text-gray-600">
                                          {detail.task}
                                        </td>
                                        <td className="border px-4 py-2 text-gray-600">
                                          {detail.hour}
                                        </td>
                                        {/* <td className="border px-4 py-2 text-gray-600">{detail.comment}</td> */}
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )
                      )}
                  </>
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DataDisplay;
