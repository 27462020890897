/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input";
import { useAppContext } from "contexts";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import apiService from "services/apiService";
import { LeavePayload } from "services/Leave/leaveInterface";
import leaveManager from "services/Leave/leaveManager";
import { storageService } from "services/storageService";
import { getMonthByDate, getYear } from "util/Util";
import * as yup from "yup";

const validationSchema = yup.object({
  leaveFrom: yup.string().required("Leave From is required"),
  leaveTo: yup.string().required("Leave To is required"),
  leaveFor: yup.string().required("Leave For is required"),
  days: yup.string(),
  leaveType: yup.string().required("Leave Type is required"),
  reason: yup.string().required("Leave Reason is required"),
});

interface IProps {
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

const leaveForOptions = [
  { value: 1, label: "Full Day" },
  { value: 0.5, label: "Half Day" },
];

const leaveTypeOptions = [
  { value: "Sick leave", label: "Sick Leave" },
  { value: "Urgent leave", label: "Urgent leave" },
  { value: "Maternity and paternity leave", label: "Maternity and paternity leave" },
  { value: "Public and religious holidays", label: "Public and religious holidays" },
  { value: "Bereavement leave", label: "Bereavement leave" },
  { value: "Time off in lieu (TOIL)", label: "Time off in lieu (TOIL)" },
  { value: "Unpaid leave", label: "Unpaid leave" },
  { value: "Paid leave", label: "Paid leave" },
  { value: "Other leave", label: "Other leave" },
];

const AddLeave = ({ onSuccess, onError, onClose }: IProps) => {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [days, setDays] = useState(0);
  const [selectedLeaveFor, setSelectedLeaveFor] = useState(1);
  const [selectedLeaveType, setSelectedLeaveType] = useState("Sick leave");
  const { user } = useAppContext();
  const[loading, setLoading] = useState(false);
  const[leaveDate, setLeaveData] = useState<any[]>([]) ;

  const getUrl = () => {
    const year = new Date().getFullYear();
    console.log(year) ;
    const store = storageService.getItem('user');
    const jsonStore = store && JSON.parse(store);

    return `/api/leave/data/${year}?email=${jsonStore.email}`
  } 

  const getLeaveData = async () =>{
    setLoading(true);
    const url = getUrl() ;
    apiService
    .get(url)
    .then(res=>{
      console.log(res);
      setLeaveData(res.data);
    })
    .catch((error)=>{
      console.log("errors : " + error) ;
    }).finally(()=>{
      setLoading(false);
    })
  };

  useEffect(()=>{
    getLeaveData()
  },[]);

  const oldDateList:string[] = leaveDate.map((date)=>{
    const dateObj = new Date(date.leaveFrom) ;
    return dateObj.toISOString().split("T")[0];
  });

  console.log(oldDateList) ;

  const inputDate = methods.getValues("leaveFrom") ;
  console.log(inputDate);

  const onSubmit = async (data: any) => {

    if(loading){
      return ;
    }

    if(oldDateList.includes(inputDate)){
      return ;
    }
    
    const payload: LeavePayload = {
      leaveTo: data.leaveTo,
      leaveFrom: data.leaveFrom,
      unit: days,
      reason: data.reason,
      leaveType: `${selectedLeaveType}`,
      status: "Submitted",
      department: "development",
      comment: "",
      year: getYear(),
      month: getMonthByDate(data.leaveTo),

      email: user.email,
      leaveAppliedDate: Date.now(),
    };

    console.log("month : " + getMonthByDate(data.leaveTo));
    console.log("paylaod", payload);
    leaveManager
      .addLeave(payload)
      .then((res) => {
        if (res.status === 200) {
          onSuccess();
        } else {
          onError();
        }
      })
      .catch((err) => {
        console.log(err);
        onError();
      });
  };

  const onLeaveForChanged = (selectedOption: any) => {
    setSelectedLeaveFor(selectedOption.value);
    methods.clearErrors("leaveFor");
  };

  const onLeaveFromChanged = (e: any) => {
    setLeaveFrom(e.target.value);
  };

  const onLeaveToChanged = (e: any) => {
    setLeaveTo(e.target.value);
  };

  const onLeaveTypeChanged = (selectedOption: any) => {
    setSelectedLeaveType(selectedOption.value);
    methods.clearErrors("leaveType");
  };

  const onDaysChange = () => {
    console.log(`on days change`);
  };

  useEffect(() => {
    if (leaveFrom && leaveTo) {
      // const convertLeaveFrom: any = new Date(leaveFrom);
      // const converLeaveTo: any = new Date(leaveTo);
      // const days: any = Math.floor((converLeaveTo - convertLeaveFrom) / (1000 * 60 * 60 * 24));
      // setDays(days + 1 - (selectedLeaveFor === 0.5 ? 0.5 : 0));
      const convertLeaveFrom: Date = new Date(leaveFrom);
      const convertLeaveTo: Date = new Date(leaveTo);
      let days = 0;
      for (let d = new Date(convertLeaveFrom); d <= convertLeaveTo; d.setDate(d.getDate() + 1)) {
        if (d.getDay() !== 6 && d.getDay() !== 0) {
          // 6 is Saturday, 0 is Sunday
          days++;
        }
      }
      if (selectedLeaveFor === 0.5) {
        days -= 0.5;
      }
      setDays(days);
    }
  }, [leaveFrom, leaveTo, selectedLeaveFor]);
  const minLeaveToDate = leaveFrom ? new Date(leaveFrom).toISOString().split("T")[0] : "";

  useEffect(() => {
    methods.setValue("leaveType", "Sick leave");
    methods.setValue("leaveFor", JSON.stringify(leaveForOptions[0].value));
  }, [methods]);

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group grid grid-rows-4 gap-y-6 gap-x-6">
            <div className="form-group grid grid-cols-3 gap-x-4">
              <div className="flex flex-col">
                <Input
                  name="leaveFrom"
                  type="date"
                  required
                  label="Leave From"
                  onChange={onLeaveFromChanged}
                  value={leaveFrom}
                />
              </div>
              <div className="flex flex-col">
                <Input
                  type="date"
                  label="Leave To"
                  name="leaveTo"
                  required
                  onChange={onLeaveToChanged}
                  value={leaveTo}
                  min={minLeaveToDate}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Leave For <span className="text-red-500"> *</span>
                </label>
                <Select
                  options={leaveForOptions}
                  className="select"
                  {...methods.register("leaveFor")}
                  onChange={onLeaveForChanged}
                  name="leaveFor"
                  // value={selectedLeaveFor}
                  value={leaveForOptions.find((op) => op.value === selectedLeaveFor)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                {/* text area needto change later for ui issue  */}
                <Input type="text" label="Leave Reason" placeholder="Reason" name="reason" required />
                {/* <Input type="number" label="Days" name="days" value={days} onChange={onDaysChange} /> */}
                <Input type="number" label="Days" name="days" value={days} onChange={onDaysChange} required />
              </div>
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Leave Type <span className="text-red-500"> *</span>
                </label>
                <Select
                  options={leaveTypeOptions}
                  {...methods.register("leaveType")}
                  name="leaveType"
                  onChange={onLeaveTypeChanged}
                  value={leaveTypeOptions.find((op) => op.value === selectedLeaveType)}
                />
              </div>
            </div>
            {/* <div>
              <Input type="textbox" label="Leave Reason" placeholder="Reason" name="reason" />
            </div> */}
            <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                type="submit"
                className={`text-white bg-blue-700 ${oldDateList.includes(inputDate)?" focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3 cursor-not-allowed":"hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"} `}
                disabled = {oldDateList.includes(inputDate)}
              >
                Submit
              </button>
            </div>
            {inputDate &&oldDateList.includes(inputDate) &&(
              <div
                className="text-red-500 text-lg"
              >
                Leave is Already Exist for this Date
              </div>
            )}
      
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddLeave;
