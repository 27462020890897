/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import apiService from "services/apiService";
export default function ViewDocs({ user }: any) {
  const [images, setImages] = useState<{ [key: string]: string } | null>(null);
  console.log("user.id", user.id);
  useEffect(() => {
    apiService
      .get(`api/Uploaddocuments`)
      .then((res) => {
        console.log("res", res);
        setImages(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="px-4 py-6">
      <h2 className="text-2xl font-bold mb-8 text-gray-800 text-center">Uploaded Files</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center">
        {images &&
          Object.entries(images).reverse().map(([fileName, fileUrl]: any) => (
            <div
              key={fileName}
              className="relative bg-white rounded-xl shadow-md p-6 w-full max-w-xs h-64 flex flex-col items-center justify-center transform transition-transform duration-300 hover:scale-105"
            >
              <img src={fileUrl.s3Url} alt={fileUrl.documentName} className="w-48 h-48 object-contain mb-4" />
              <p className="text-sm text-center text-gray-700 font-semibold truncate">{fileUrl.documentName}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
