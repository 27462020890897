import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../components/Input";
import Button from "components/Button";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import appConfig from "services/apiConfig";
import { storageService } from "services/storageService";
import imageUrl from "assets/lirisoft-logo.png";
import apiService from "services/apiService";
import { useToaster } from "contexts/toasterContext";

interface FormData {
  password: string;
  email: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function Login() {
  const navigate = useNavigate();
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });
  const { search } = useLocation();
  const { addToast } = useToaster();

  const checkUserLoggedIn = () => {
    const token = storageService.getItem("accessToken");
    console.log("token ", token);
    if (token) {
      navigate("/admin/profile");
    } else {
      const token = new URLSearchParams(search).get("token");
      if (token) {
        console.log("Login Page: User logged in! Token value: ", search, token);
        storageService.setItem("accessToken", token);
        navigate("/admin/profile");
      } else {
        console.error("User is not logged in ", token);
      }
    }
  };
  const onSubmit = (data: FormData) => {
    console.log(data);
    apiService
      .post("api/login", data)
      .then((res) => {
        console.log(res);
        const accessToken = res.data;
        res && storageService.setItem("accessToken", accessToken);
        checkUserLoggedIn();
        addToast("Login Succes ", "success");
      })
      .catch((error) => {
        console.error(error);
        addToast("Email or Password is Wrong Please check", "error");
      });
    // checkUserLoggedIn(data);
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, [search]);

  return (
    <div className="flex items-center justify-center min-h-screen h-screen p-6 ">
      <div className="flex flex-col lg:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden border-2	">
        {/* Logo Section */}
        <div className="flex items-center justify-center  w-full lg:w-1/2 p-8 bg-gray-100">
          <div className="flex flex-col items-center">
            <img
              src={imageUrl}
              alt="Lirisoft Logo"
              className="w-60 h-auto mb-4"
              loading="lazy"
            />
            <h4 className="text-2xl font-bold text-gray-900 text-center ">
              Use Your Lirisoft <br /> Account
            </h4>
          </div>
        </div>

        {/* Form Section */}
        <div className="flex items-center justify-center w-full lg:w-1/2 p-8">
          <div className="w-full max-w-md">
            <h1 className="text-3xl font-bold text-gray-900 text-center mb-6">
              Sign In
            </h1>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Please Enter the Email"
                />
                <Input
                  name="password"
                  label="Password"
                  placeholder="******"
                  type="password"
                />
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 focus:ring-2"
                    />
                    <label
                      htmlFor="remember"
                      className="ml-2 text-sm text-gray-600"
                    >
                      Remember me
                    </label>
                  </div>
                  <a
                    href="#"
                    className="text-sm font-medium text-primary-600 hover:underline"
                  >
                    Forgot password?
                  </a>
                </div>
                <Button type="submit" className="w-full">
                  Submit
                </Button>
              </form>
            </FormProvider>
            <div className="mt-6">
              <a href={appConfig.googleLogin()} className="w-full">
                <button
                  type="button"
                  className="w-full bg-white border border-primary-900 text-primary-600 font-medium rounded-lg text-sm px-5 py-2.5 shadow-sm hover:bg-gray-50"
                >
                  <span className="flex items-center justify-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      width="46"
                      height="47"
                      viewBox="0 0 46 47"
                      fill="none"
                    >
                      <path
                        d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                        fill="#34A853"
                      />
                      <path
                        d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                        fill="#EB4335"
                      />
                    </svg>
                    Sign in with Google
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
