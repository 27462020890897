/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import apiService from "services/apiService";
import { skillService } from "./skillService";

interface Skill {
  skill: string;
}

interface OptionType {
  label: string;
  value: string;
}

const Skills = ({ user }: any) => {
  const [allskills, setAllSkills] = useState<OptionType[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<OptionType[]>([]);
  const [userSkills, setUserSkills] = useState<OptionType[]>([]); // State for user-specific skills

  const [pendingSkills, setPendingSkills] = useState<OptionType[]>([]); // State for pending skills
  const fetchUserSkill = () => {
    apiService
      .get(`api/user/skill/${user.id}`)
      .then((res) => {
        // user skills
        const userSkillsData = res.data.map((skill: any) => ({
          label: skill.skill,
          value: skill.id,
        }));
        setUserSkills(userSkillsData); // Set user-specific skills in state
        // setSelectedSkills(userSkillsData); // Initially, the selected skills are the user-specific skills
      })
      .catch((err) => {
        console.log("Error fetching user skills", err);
      });
  };
  useEffect(() => {
    // Fetch all available skills from the API
    apiService
      .get("api/skill")
      .then((res) => {
        const skillOptions = res.data.map((skill: Skill) => ({
          label: skill.skill,
          value: skill.skill,
        }));
        setAllSkills(skillOptions);
      })
      .catch((err) => {
        console.log("Error fetching skills", err);
      });

    // Fetch user-specific skills
    fetchUserSkill();
  }, []);

  useEffect(() => {
    // Calculate pendingSkills: allskills - selectedSkills
    const pending = allskills.filter((skill) => !userSkills.some((selected) => selected.value === skill.value));
    setPendingSkills(pending);
  }, [allskills, userSkills]);

  const addSkill = () => {
    // console.log("selectedSkills", selectedSkills);
    const convertedData = selectedSkills.map((item) => ({ skill: item.label }));
    console.log("convertedData", convertedData);
    // setSelectedSkills([...selectedSkills, { label: newSkill, value: newSkill }]);

    apiService
      .post("api/addSkill", convertedData)
      .then((res) => {
        console.log("Skill added", res.data);
        fetchUserSkill();
        setSelectedSkills([]);
      })
      .catch((err) => {
        console.log("Error adding skill", err);
      });
  };

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedSkills(selectedOptions as OptionType[]);
  };

  const deleteSkill = (skillToRemove: OptionType) => {
    console.log("skillToRemove", skillToRemove);
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", user.id);
    urlencoded.append("skillId", skillToRemove.value);
    skillService
      .deleteWithBody("api/skill", urlencoded)
      .then((res) => {
        console.log("res", res);
        fetchUserSkill();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [skillsImg, setSkillsImg] = useState("");
  useEffect(() => {
    function convertToCommaSeparatedString(dataArray: any[]): string {
      // Check if the array is empty or undefined
      if (!dataArray || dataArray.length === 0) {
        return ""; // Return an empty string if there's no data
      }

      return dataArray.map((item) => item.label).join(",");
    }

    const result = convertToCommaSeparatedString(userSkills);

    setSkillsImg(result);
  }, [userSkills]);
  // Use the function

  // const result = convertSkillsToString(userSkills);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="text-center text-3xl font-bold text-gray-900">Skill Management</h2>

        <div className="mt-8 space-y-6">
          {/* Multi-Select Input with react-select */}
          <Select
            isMulti
            value={selectedSkills}
            onChange={handleSelectChange}
            options={pendingSkills}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Search and select skills..."
          />
          <button
            type="button"
            onClick={addSkill}
            className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Skill
          </button>
        </div>
        {skillsImg && (
          <div className="p-4 bg-gray-300 rounded-md shadow">
            <img src={`https://skillicons.dev/icons?i=${skillsImg}&theme=light&perline=8`} />
          </div>
        )}

        {/* Skill List */}
        <div className="bg-white shadow sm:rounded-lg mt-4">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Selected Skills</h3>
            <ul className="mt-4 space-y-2">
              {userSkills.length > 0 ? (
                userSkills.map((skill, index) => (
                  <li key={index} className="flex justify-between items-center bg-gray-50 px-4 py-2 rounded-md shadow ">
                    <span style={{ textTransform: "capitalize" }}>{skill.label}</span>
                    <button onClick={() => deleteSkill(skill)} className="text-red-500 hover:text-red-800">
                      Delete
                    </button>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">No skills selected yet.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
